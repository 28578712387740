import { Spinner } from 'src/components/Spinner/Spinner';
import LogoImage from 'src/icons/logo.svg';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import styled from 'styled-components';

export const SplashScreen = () => {
    return (
        <StyledAppSplashScreen>
            <StyledWrapper>
                <StyledImage
                    maxWidth={ThemeStore.getLogoMaxSize}
                    src={LogoImage}
                />
            </StyledWrapper>
            <Spinner />
        </StyledAppSplashScreen>
    );
};

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledWrapper = styled.div`
    color: #0d0f1c;
    display: flex;
    margin-bottom: 30px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;
const StyledImage = styled.img<{ maxWidth?: string }>`
    max-width: ${(props) => props.maxWidth};
`;
