// eslint-disable-next-line simple-import-sort/sort
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FormioCustomEvent, FormioEvent } from 'src/core/Formio.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { useTaskInterval } from 'src/hooks/useTaskInterval';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export type TaskFormProps = {
    taskId: string;
};
export const TaskForm = observer(({ taskId }: TaskFormProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (AssignedTasksStore.list === undefined) {
            AssignedTasksStore.loadList();
        }
    }, [AssignedTasksStore.list]);

    const taskDefinition = useMemo(() => {
        return AssignedTasksStore.list.find(
            (taskDefinition) => taskDefinition.id === taskId,
        );
    }, [AssignedTasksStore.list, taskId]);

    useEffect(() => {
        if (!taskDefinition) {
            AssignedTasksStore.loadList();
        }
    }, [taskId]);

    useEffect(() => {
        return () => {
            AssignedTasksStore.listLoader.reset();
            AssignedTasksStore.itemLoader.reset();
        };
    }, []);

    useEffect(() => {
        if (!taskDefinition) {
            return;
        }
        CurrentTaskStore.loadItem(taskDefinition.id);
        CurrentTaskStore.loadForm(taskDefinition.id);
    }, [taskDefinition]);

    useTaskInterval(taskDefinition);

    const isLoading =
        CurrentTaskStore.formLoader.isLoading ||
        CurrentTaskStore.itemLoader.isLoading ||
        AssignedTasksStore.listLoader.isLoading;

    if (isLoading) {
        return <StyledSpinner />;
    }

    if (!taskDefinition) {
        return <Redirect to={RoutePaths.index} />;
    }

    const hasL10nTitleKey = Boolean(taskDefinition?.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${taskDefinition?.extensions?.l10nTitleKey}`, {
              ...taskDefinition?.taskInfo,
          })
        : taskDefinition?.name;

    return (
        <>
            <StyledTaskHeader>{title}</StyledTaskHeader>
            <FormioForm
                key={taskId}
                form={CurrentTaskStore.currentItemForm as any}
                submission={CurrentTaskStore.getInitialVariables()}
                onSubmit={getSubmitHandler(taskId)}
                onCustomEvent={getCustomEventHandler(taskId)}
                normalizeSubmission
            />
        </>
    );
});

const StyledTaskHeader = styled.h1`
    margin-bottom: 0;

    @media (max-width: 576px) {
        font-size: 32px;
    }
`;

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const getSubmitHandler = (taskId: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        const { data } = values;
        await submitForm(taskId, data);
    };
};

const submitForm = async (taskId: string, data: any) => {
    const tasks = await CurrentTaskStore.submitForm(taskId, data);

    if (tasks?.length) {
        Router.navigate(
            UrlHelper.getTargetUrl(RoutePaths.task, {
                taskId: tasks[0].id!,
            }),
        );
    } else {
        Router.navigate(RoutePaths.index);
    }
};

const getCustomEventHandler = (processKey: string) => {
    return async (event: FormioCustomEvent<'submitWithoutData'>) => {
        if (event.type === 'submitWithoutData') {
            const data = _.pick(event.data, [event.component.key]);
            await submitForm(processKey, data);
        }
    };
};
