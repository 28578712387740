import { Layout, Typography } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'src/components/Spinner/Spinner';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import styled from 'styled-components';

export enum PageType {
    BASE,
    WIDE_FORM,
    SIMPLE_FORM,
    FULL = 'FUll',
}
export type BasePageProps = {
    title?: React.ReactNode;
    pageName: string;
    isLoading?: boolean;
    isUpdating?: boolean;
    type?: PageType;
    selectorPageName?: string;
};
export const BasePage: React.FC<BasePageProps> = observer(
    ({ children, isLoading, title, type, pageName, selectorPageName }) => {
        return (
            <StyledContent
                type={(ThemeStore.getBasePageMaxWidth as PageType) || type}
                width={ThemeStore.getBasePageWidth}
                pageName={pageName}
                selectorPageName={selectorPageName}
            >
                {title && <StyledPageTitle>{title}</StyledPageTitle>}
                {isLoading ? <StyledSpinner /> : children}
            </StyledContent>
        );
    },
);

export const StyledContent = styled<
    React.FC<BasicProps & { type?: PageType; width?: string }>
>(Layout.Content)`
    max-width: ${(props) => getMaxWithByType(props.type)};
    width: ${(props) => props.width || '100%'};
    margin: 0 auto;
    position: relative;
    padding-bottom: 20px;
`;

const getMaxWithByType = (type?: PageType) => {
    if (type === PageType.WIDE_FORM) {
        return '800px';
    }
    if (type === PageType.SIMPLE_FORM) {
        return '580px';
    }
    if (type === ('FULL' as PageType)) {
        return '100%';
    }

    return '70%';
};
const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const StyledPageTitle = styled(Typography.Title)`
    font-weight: 700;
    line-height: 105%;
    letter-spacing: -0.03em;
    padding-left: 30px;
    color: var(--form-text-color) !important;

    &.ant-typography {
        margin-bottom: 43px;
        font-size: 48px;
        font-weight: 700;
    }

    @media (max-width: 576px) {
        padding-left: 0;

        &.ant-typography {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }
`;
