import { asMilliseconds } from 'pomeranian-durations';
import { useEffect } from 'react';
// import { usePageVisibility } from 'react-page-visibility';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ExtentendetTaskRepresentation } from 'src/services/api.types';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';

export const useTaskInterval = (
    taskDefinition?: ExtentendetTaskRepresentation,
) => {
    // const isVisible = usePageVisibility();
    const isVisible = true;

    useEffect(() => {
        let interval: number;

        if (taskDefinition?.extensions?.autoUpdateInterval && isVisible) {
            const intervalInMilliseconds = asMilliseconds(
                taskDefinition.extensions.autoUpdateInterval,
            );

            interval = window.setInterval(async () => {
                await AssignedTasksStore.loadItem(taskDefinition.id);
                if (AssignedTasksStore.itemLoader.hasError) {
                    Router.navigate(RoutePaths.index);
                }
            }, intervalInMilliseconds);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isVisible]);
};
