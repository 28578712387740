import styled from 'styled-components';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooter>
            &copy; {currentYear} All Rights Reserved. Fintaza. Cookie
            Preferences, Privacy, and Terms.
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    padding: 10px 46px 20px 46px;
    color: var(--footer-text-color);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    background: var(--footer-bg);

    @media (max-width: 768px) {
        padding: 0 20px 10px;
    }
`;
